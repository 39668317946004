/**
 * The page load cycle (just in terms of html and css/js loading) currently:
 * - sets "site settings" (run-time config)
 * - [blocking] loads and executes ViewerPageInitialization javascript immediately. Parsing of the URL.
 * - executes google analytics and userguiding javascript wrapper code.
 * - loads/executes thirdparty javascript
 * - invokes deferred loading of v3/viewer-page.js
 * - loads corpus and uiv2.js
 * - begins to fill the DOM with body elements
 * - loads syncv2.js, uiv2-bottom.js, and some inline code
 * - sets a document.ready call to initialize the page
 * - executes deffered v3/viewer-page.js
 * - the document.ready call is invoked to initializeViewerPage and initializeContent (viewer content query)
 *
 * @file The "viewer" web page.
*/
import { IrApi } from '../ir-api.es12.js';
import { IrApi as IrApi2 } from '../api/api.es6.js';
import { IrSession } from '../ir-session.es12.js';
import { LeadModel } from './lead-model.es12.js';
import { ViewerModel } from './viewer-model.es12.js';
import { ViewerView } from './viewer-view.es12.js';
import { ViewerController } from './viewer-controller.es12.js';
import { EditMode } from './edit-mode.es13.js';
import { ActivityMonitor } from '../activity/activity-monitor.es6.js';
import { ContentModelSimulator } from '../models/content-model-simulator.es13.js'
import { ChatController } from '../chat/chat-controller.es12';
import { ChatLegacyShim } from '../chat/chat-legacy-shim.es12';
import { ChatModel } from '../chat/chat-model.es12';
import { ChatView } from '../chat/chat-view.es12';
import { PreloadContent } from '../utils/preload-content.es13';
import { AiService } from '../hey-infinityy/ai-service.es.js';
import { Hal9000 } from './hal9000.es.js';
import { ViewerIframeApi } from './viewer-iframe-api.es.js';
import { GAHandler } from '../activity/ga-handler.mjs';
import { onboarding } from '../onboarding/onboarding.mjs';

console.debug('[page]', 'Initializing viewer page.', Date.now() * .001);

const ViewerPage = {};

// Startup Analytics
const gaHandler = new GAHandler(window.infinityyEnvironmentSettings.GoogleAnalyticsSiteId);
gaHandler.initialize();
// There are some places still using global gtag(); one of the API classes
// and Diagnostics (used by conference calling). For now, just make sure that
// global is in place. Later, refactor those 2 places to not use global.
window.gtag = (ev, name, data) => {
	gaHandler.gtag(ev, name, data);
};

// Things loaded not by webpack, that webpacked scripts need.
const siteSettings = window.siteSettings;
const toastrWrapper = window.EveryScape.ToastrWrapper;
const newCallosumClient = window.EveryScape.Callosum.Client;
const location = window.location;

// Dependency injections.
const api2 = new IrApi2(window.infinityyEnvironmentSettings.InfinityyApiUrl);
const session = new IrSession({ api: api2, settings: window.infinityyEnvironmentSettings });
const api = new IrApi({ rootUrl: window.infinityyEnvironmentSettings.InfinityyApiUrl, session: session });
const preloadContent = new PreloadContent(session, gaHandler);

api.getPageInitializationData().then((pageInitialData) => {
	const roomId = +(pageInitialData?.room?.id);
	session.setIdentity(pageInitialData?.user);
	onboarding.identifyUser(`identityId:${session.identityId}`);

	EveryScape.SyncV2.CurrentState.chatId = roomId;
	EveryScape.SyncV2.trackSystemInteraction('page_load', { userAgent: navigator.userAgent });
	preloadContent.recordPageView(pageInitialData?.content);
	EveryScape.SyncV2.initializeChat()
		.then(() => { console.debug('[page]', 'Conversation History Loaded', Date.now() * .001); });
	initializeViewerPage(roomId);
	EveryScape.SyncV2.UI.updateUserIdentityUI();
});

function initializeViewerPage(roomId) {
	window.initTwilio(roomId);
	const leadModel = new LeadModel({ session: session, settings: window.infinityyEnvironmentSettings });
	const model = new ViewerModel({ api: api, session: session, roomId: roomId, settings: window.siteSettings });
	const viewerIframeApi = new ViewerIframeApi({ viewerModel: model });
	const chatShim = new ChatLegacyShim(window);
	const chatModel = new ChatModel({
		contentId: chatShim.contentId,
		roomId: roomId,
		settings: siteSettings,
		shim: chatShim
	});
	const chatView = new ChatView({
		alerter: toastrWrapper,
		model: chatModel,
		shim: chatShim
	});
	const callosumClient = newCallosumClient(siteSettings.Callosum.HubUrl);
	const chatController = new ChatController({
		alertOnReceivedMessages: true,
		callosumClient: callosumClient,
		location: location,
		model: chatModel,
		settings: siteSettings,
		view: chatView
	});
	const editMode = new EditMode({ api: api, model: model });
	const activityMonitor = new ActivityMonitor({
		clientInstanceId: window.clientInstanceId,
		contentModel: new ContentModelSimulator(model),
		leadModel: leadModel,
		infinityyApiUrl: window.siteSettings.InfinityyApiUrl,
		roomId: roomId,
		session: session,
		settings: siteSettings,
		userModel: EveryScape.SyncV2.CurrentState.self,
		viewerModel: model
	});
	const view = new ViewerView({
		activityMonitor: activityMonitor,
		leadModel: leadModel,
		model: model,
		settings: window.infinityyEnvironmentSettings
	});
	const aiService = new AiService({ settings: siteSettings, viewerModel: model });
	const ai = new Hal9000({
		activityMonitor: activityMonitor,
		aiService: aiService,
		session: session,
		settings: window.infinityyEnvironmentSettings,
		viewerModel: model,
	});
	const controller = new ViewerController({
		activityMonitor: activityMonitor,
		ai: ai,
		aiService: aiService,
		api: api,
		chatController: chatController,
		irApi: api2,
		leadModel: leadModel,
		model: model,
		session: session,
		view: view
	});
	controller.setNewChatId(roomId);

	ViewerPage.activityMonitor = activityMonitor;
	ViewerPage.aiService = aiService;
	ViewerPage.api = api;
	ViewerPage.chatController = chatController;
	ViewerPage.controller = controller;
	ViewerPage.editMode = editMode;
	ViewerPage.hal9000 = ai;
	ViewerPage.leadModel = leadModel;
	ViewerPage.model = model;
	ViewerPage.preloadContent = preloadContent;
	ViewerPage.session = session;
	ViewerPage.view = view;
	ViewerPage.viewerIframeApi = viewerIframeApi;

	window.callosumClientUser = callosumClient;
	window.clientInstanceId = window.callosumClientUser?.clientInstanceId;

	controller.initialize();
	chatController.initialize();
	EveryScape.SyncV2.Telemetry = new AcTelemetryClient(EveryScape.SyncV2.Session.clientInstanceId, model.telemetryChannelId,
		model.chatId, new Diagnostics(),
		{
			sendTelemetryInterval: +(siteSettings.Telemetry.SendInterval),
			disconnectedTimeout: +(siteSettings.Telemetry.DisconnectedTimeout)
		});
	initDashboardSideBar("");
}

window.page = ViewerPage;

export { ViewerPage }
