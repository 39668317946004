import userflow from 'userflow.js'

userflow.init(window.siteSettings?.userflow?.token);
userflow.setResourceCenterLauncherHidden(true);

const identifyUser = (userIdentityString) => {
	userflow.identify(userIdentityString);
}

const showHelp = () => {
	userflow.openResourceCenter();
}

const toggleHelp = () => {
	userflow.toggleResourceCenter();
}

const onboarding = {
	identifyUser: identifyUser,
	showHelp: showHelp,
	toggleHelp: toggleHelp
}

export { onboarding }
