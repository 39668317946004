import { IrUtils } from '../utils/utils.es13.js';
import stringInject from 'stringinject';
import { onboarding } from '../onboarding/onboarding.mjs';

/**
 * View for the viewer page.
 * WIP wrapping and migrating functionality from legacy code.
 */
const viewerUserGuidesId = +(window.infinityyEnvironmentSettings.UserGuiding.ViewerUserGuidesId);

class ViewerView {
	constructor(options) {
		this._activityMonitor = options.activityMonitor;
		this._$availabilityDialog = null;
		this._chatCallbackAfterLeadCapture = null;
		this._leadModel = options.leadModel;
		this._model = options.model;
		this._settings = options.settings;
		this.onFeatureResequence = (_evt, _ui) => { return false; };
		this.onLeadCaptureDialogClose = () => { return false; };
		this.onOpenDashboard = (_evt) => { return false; };
	}

	/**
	 * If the user isn't logged in and hasn't provided contact info yet, request it from them.
	 * @param {any} callback
	 * @returns
	 */
	getLeadBeforeSendingChat(callback) {
		if (!this._model.isChatLeadCaptureEnabled || this._model.isLoggedIn || this._leadModel.isLeadClientValid()) {
			return new Promise(callback);
		} else {
			this._chatCallbackAfterLeadCapture = callback;
			this._$chatLeadCaptureDialog.dialog('open');
		}
		return this;
	}

	handleUserIdentificationComplete() {
		$('.ai-model-setting').toggle(this._model.session.isInternal);
		return this;
	}

	initialize() {
		$('#UserGuidesButton').show().on('click', () => {
			onboarding.showHelp();
		});

		$("#ContentItemsList, #UDGItemsList").sortable({
			axis: "y",
			revert: true,
			scroll: true,
			cursor: "move",
			delay: 150,
			helper: 'clone',
			tolerance: "intersect",
			start: (_evt, ui) => { ui.item.data('start_pos', ui.item.index()); },
			update: (evt, ui) => { this.onFeatureResequence(evt, ui) }
		});

		$(".ui-sortable").sortable("disable");
		return this._initializeRequestAccessDialog()
			._initializeAvailabilityDialog()
			._initializeChatLeadCaptureDialog()
			._initializeSplashLeadCaptureDialog();
	}

	enableAi() {
		$("#ViewerAndBottomDrawer").addClass('ai-enabled');
	}

	onCheckAvailabilityClick(dynamicListingData, listing) {
		const $dialog = this._$availabilityDialog;
		$dialog.dialog('open');
		$('.ui-widget-overlay').unbind('click').click(() => {
			$dialog.dialog('close');
		});
		$dialog.find('.ifp-modal-name').text(listing.Name);
		$dialog.find('.beds').text((0 === listing.Bedrooms) ? 'Studio' : `${listing.Bedrooms || '-'} Beds`);
		$dialog.find('.baths').text(`${listing.Bathrooms} Baths`);
		$dialog.find('.area').text(listing.SquareFeet);
		const minPrice = dynamicListingData.units.reduce((a, b) => a.Price < b.Price ? a : b).Price;
		$dialog.find('.ifp-modal-price').text(`Starting at \$${minPrice.toLocaleString()}`);

		const now = new Date();
		// Construct the table dynamically, based on what fields are present in the data
		const $table = $('<table>');
		const $headerRow = $('<tr>');
		$table.append($headerRow);
		const fieldMap = new Map([
			['UnitNumber', 'Apt #'],
			['FloorNumber', 'Floor'],
			['Price', 'Starting At'],
			['Deposit', 'Deposit'],
			['Available', 'Available On']
		]);

		const emptyColumns = [];
		fieldMap.forEach((value, key) => {
			if (dynamicListingData.units.filter((u) => { return !IrUtils.isNullyOrEmptyString(u[key]) }).length > 0) {
				$headerRow.append(`<th>${value}</th>`);
			} else {
				emptyColumns.push(key);
			}
		});
		$headerRow.append('<th>'); // make the column count the same as the rows below because of the lease now button
		$.unique(emptyColumns).forEach((k) => { fieldMap.delete(k) });

		for (let u of dynamicListingData.units) {
			const available = new Date(u.Available);
			const availableText = u.Available ? ((available < now) ? 'Available Now' : available.toDateString()) : '';
			const depositText = IrUtils.isNully(u.Deposit) ? '' : u.Deposit.toLocaleString();
			const $row = $('<tr>');
			if (fieldMap.has('UnitNumber')) { $row.append($('<td>').text(u.UnitNumber)); }
			if (fieldMap.has('FloorNumber')) { $row.append($('<td>').text(u.FloorNumber)); }
			if (fieldMap.has('Price')) { $row.append($('<td>').text(`\$${u.Price.toLocaleString()}`)); }
			if (fieldMap.has('Deposit')) { $row.append($('<td>').text(depositText)); }
			if (fieldMap.has('Available')) { $row.append($('<td>').text(availableText)); }
			const $leaseNowButton = $('<button>').addClass('ifp-modal-lease-now-button').data('unit', u).text('Lease Now');
			$row.append($('<td>').append($leaseNowButton));
			$leaseNowButton.click((_evt) => { return this._onOpenDatePicker(u); });
			$table.append($row);
		}

		$dialog.find('.ifp-modal-availability-section').empty().append($table);
		$dialog.find('.ifp-close-button').unbind('click').click(() => {
			$dialog.dialog('close');
		});
		return false;
	}

	setContentLoaded() {
		this._$requestAccessSection.toggle(!this._leadModel.hasRequestedProjectAccess(this._model.projectId) && this._model.isRequestAccessButtonEnabled);
		if (this._model.isRequestAccessButtonEnabled) {
			if (this._model.requestAccessButtonText) {
				$('.request-access-button-text').text(this._model.requestAccessButtonText);
			}
			if (this._model.requestAccessDialogMarkdown) {
				$('#RequestAccessDialogProjectContentInner').html(new showdown.Converter().makeHtml(this._model.requestAccessDialogMarkdown));
			}
		}
		if (this._model.isChatLeadCaptureEnabled && this._model.chatLeadCaptureDialogMarkdown) {
			$('#ChatLeadCaptureDialogContentInner').html(new showdown.Converter().makeHtml(this._model.chatLeadCaptureDialogMarkdown));
		}
		return this._updateTryItBanner();
	}

	showSplashLeadCaptureDialog() {
		const customBackgroundUrl = window.page?.preloadContent?.content?.coverPhotoUrl;
		if (customBackgroundUrl) {
			this._$splashLeadCaptureDialog.css('background-image', `url('${customBackgroundUrl}')`);
		}
		const fields = this._model.splashScreenFields;
		let $focusTarget = null;

		this._toggleDisabledSplashLeadCaptureDialogSubmitButton(!this._isSplashLeadCaptureFormValid());

		// sanity check: if no fields are set to visible, default to email visible and required
		if (fields.visible.size == 0) {
			fields.visible.set('email', true);
			fields.required.set('email', true);
		}

		const setupField = ($el, key) => {
			$el.toggleClass('required', !!fields.required.get(key)).toggleClass('hidden', !fields.visible.get(key));
			if (!$focusTarget && !!fields.required.get(key)) {
				$focusTarget = $el.find('input');
			}
		}
		setupField(this._$splashLeadCaptureName, 'name');
		setupField(this._$splashLeadCaptureEmail, 'email');
		setupField(this._$splashLeadCapturePhone, 'phone');

		this._$splashLeadCaptureDialog.one('shown.bs.modal', () => {
			$focusTarget?.trigger('focus');
		}).modal('show');

		return this;
	}

	_getAmenitiesTabButton() {
		switch (EveryScape.SyncV2.ProjectType) {
			case 'marketingcenter':
			case 'multifamily':
				return $('#BuildingAmenitiesTab');
			default:
				return $();
		}
	}

	_getFloorplansTabButton() {
		switch (EveryScape.SyncV2.ProjectType) {
			case 'marketingcenter':
			case 'multifamily':
				return $('#BuildingFloorPlansTab');
			default:
				return $();
		}
	}

	_getPropertyTabButton() {
		switch (EveryScape.SyncV2.ProjectType) {
			case 'residential':
				return $('#ResBuildingTab');
			case 'marketingcenter':
				return $('#MCBuildingTab');
			case 'multifamily':
				return $('#BuildingTab');
			default:
				return $();
		}
	}

	_getNeighborhoodTabButton() {
		switch (EveryScape.SyncV2.ProjectType) {
			case 'residential':
				return $('#ResBuildingNeighborhoodTab');
			case 'marketingcenter':
				return $('#MCBuildingLocationTab');
			case 'multifamily':
				return $('#BuildingNeighborhoodTab');
			default:
				return $();
		}
	}

	setBuildingTabForSelectedListing() {
		// Note: this is a bit hacky. Ideally we'd be storing explicitly what tab is selected, detecting a change, and then updating UI elements. Rather than seeing if we need to "click" a button.
		const selectedListing = this._model.getSelectedListing();
		let $tabButton;
		if (selectedListing?.PropertyUnitType) { // Amenity or marketable
			$tabButton = this._getPropertyTabButton();
		} else if (selectedListing) { // UDG
			$tabButton = this._getNeighborhoodTabButton();
		}
		if ($tabButton && !$tabButton.hasClass('selected')) {
			console.debug('[content-area]', `Toggling property/neighborhood tab.`, Date.now() * .001);
			$tabButton.click();
		}
		return this;
	}

	setSpaceTabForSelectedListing() {
		const selectedListing = this._model.getSelectedListing();
		let $tabButton;
		if (selectedListing?.PropertyUnitType) { // Amenity or marketable
			if ('amenity' === selectedListing.PropertyUnitType.toLowerCase()) {
				$tabButton = this._getAmenitiesTabButton();
			} else {
				$tabButton = this._getFloorplansTabButton();
			}
		}
		if ($tabButton && !$tabButton.hasClass('selected')) {
			console.debug('[content-area]', `Toggling floorplan/amenity tab.`, Date.now() * .001);
			$tabButton.parent().find('.selected').removeClass('selected');
			$tabButton.addClass('selected');
		}
		if (selectedListing?.PropertyUnitType) {
			// Ensure list view not group view.
			EveryScape.SyncV2.SidebarUI.toggleFeaturesList(true); // Switch out of group view to listing view.
		} else { // UDG
			// show listing view not group view.
			$(".groupHolder").hide();
			$("#UDGItemsHolder").show();
			const $groupTab = $(`#GroupTab-${selectedListing.UserDefinedGroupId}`);
			if (selectedListing?.UserDefinedGroupId && !$groupTab.hasClass('selected')) {
				$groupTab.parent().find('> .selected').removeClass('selected active expanded');
				$groupTab.addClass('selected active expanded');
			}
		}
		return this;
	}

	setUserIsAuthenticated() { // Called when a user has logged in.
		return this._enableDashboardButtons()._updateTryItBanner();
	}

	updateTileImage(listingId, thumbnailUrl) {
		$(`#GroupListingTile-${listingId},#ListingCellTile-${listingId}`).css('background-image', `url(${thumbnailUrl})`);
	}

	_enableDashboardButtons() {
		$('#BrandHomeBtn,#BrandHomeBtn2').removeClass('hideIfNotBrandAccount showAfterUserLoad hidden').show()
			.unbind('click')
			.click((evt) => {
				this.onOpenDashboard(evt);
			});
		return this;
	}

	_getFormContact($formFields) {
		const result = {};
		$formFields.each((_i, el) => {
			const name = $(el).attr('name');
			const val = $(el).val();
			if (val) {
				result[name] = val;
			}
		});
		return result;
	}

	/**
	 * Get data from the "Chat Lead Capture" form.
	 * @returns {LeadContact}
	 */
	_getChatLeadCaptureFormContact() {
		return this._getFormContact(this._$chatLeadCaptureFormFields);
	}

	/**
	 * Get data from the "Request Access" form.
	 * @returns {LeadContact}
	 */
	_getRequestAccessFormContact() {
		return this._getFormContact(this._$requestAccessFormFields);
	}

	_initializeAvailabilityDialog() {
		this._$availabilityDialog = $('.ifp-modal-cell').dialog({
			autoOpen: false, dialogClass: 'ifp-dialog', modal: true,
			position: ['center', 100], resizable: false, width: 'auto',
			classes: {
				'ui-dialog': 'ifp-dialog'
			}
		});
		return this;
	}

	_initializeChatLeadCaptureDialog() {
		const $dialogDiv = $('#ChatLeadCaptureDialog');
		this._$chatLeadCaptureDialogSubmitButton = $dialogDiv.find('button.submit');
		this._$chatLeadCaptureForm = $('.chat-lead-capture-form');
		this._$chatLeadCaptureFormFields = this._$chatLeadCaptureForm.find('input,textarea');

		this._$chatLeadCaptureDialog = $dialogDiv.dialog({
			autoOpen: false, dialogClass: 'chat-lead-capture-dialog', maxWidth: '520px',
			modal: true, resizable: false, width: 'auto',
			classes: {
				'ui-dialog': 'chat-lead-capture-dialog'
			}
		});
		$dialogDiv.on('dialogopen', () => {
			this._toggleDisabledChatLeadCaptureDialogSubmitButton(!this._isChatLeadCaptureFormValid());
			// Hacky "force horizontal center".
			$('.chat-lead-capture-dialog').css({ left: 'max(0px, calc(50% - 260px))' });
		});
		this._$chatLeadCaptureFormFields.on('input', () => {
			this._toggleDisabledChatLeadCaptureDialogSubmitButton(!this._isChatLeadCaptureFormValid());
		});
		$dialogDiv.find('button.close,button.cancel').on('click', () => {
			this._$chatLeadCaptureDialog.dialog('close');
			this._chatCallbackAfterLeadCapture = null;
		});
		this._$chatLeadCaptureDialogSubmitButton.on('click', () => { this._onSubmitChatLeadCaptureForm(); });
		return this;
	}

	_initializeRequestAccessButtonSection() {
		this._$requestAccessSection = $('.request-access-button-section').toggle(this._model.isRequestAccessButtonEnabled);
		this._$requestAccessButton = $('.request-access-button');
		this._$requestAccessButton.on('click', () => {
			if (this._model._project?.ExtraJson?.RequestAccess?.Enabled) {
				$('#ModalContactUs').modal('hide');
				this._setRequestAccessFormContact()
					._toggleDisabledRequestAccessDialogSubmitButton(!this._isRequestAccessFormValid());
				this._$requestAccessDialog.dialog('open');
			}
		});
		if (this._model?._project?.RequestAccess?.Enabled && this._model?._project?.ExtraJson?.RequestAccess?.ButtonText) {
			$('.request-access-button-text').text(this._model?._project?.ExtraJson?.RequestAccess?.ButtonText);
		}
		return this._toggleDisabledRequestAccessDialogSubmitButton(!this._isRequestAccessFormValid());
	}

	_initializeRequestAccessDialog() {
		const $dialogDiv = $('#RequestAccessDialog');
		this._$requestAccessDialogSubmitButton = $dialogDiv.find('button.submit');
		this._$requestAccessForm = $('.request-access-form');
		this._$requestAccessFormFields = this._$requestAccessForm.find('input,textarea');

		this._$requestAccessDialog = $dialogDiv.dialog({
			autoOpen: false, dialogClass: 'request-access-dialog', maxWidth: '520px',
			modal: true, resizable: false, width: 'auto',
			classes: {
				'ui-dialog': 'request-access-dialog'
			}
		});
		$dialogDiv.on('dialogopen', () => {
			// Hacky "force horizontal center".
			$('.request-access-dialog').css({ left: 'max(0px, calc(50% - 260px))' });
		});
		this._initializeRequestAccessButtonSection();
		this._$requestAccessFormFields.on('input', () => {
			this._toggleDisabledRequestAccessDialogSubmitButton(!this._isRequestAccessFormValid());
		});
		$dialogDiv.find('button.close,button.cancel').on('click', () => {
			this._$requestAccessDialog.dialog('close');
		});
		this._$requestAccessDialogSubmitButton.on('click', () => { this._onSubmitRequestAccessForm(); });
		return this;
	}

	_initializeSplashLeadCaptureDialog() {
		this._$splashLeadCaptureDialog = $('#SplashLeadCaptureDialogWrapper');
		this._$splashLeadCaptureForm = $('#SplashLeadCaptureForm');
		this._$splashLeadCaptureFormFields = this._$splashLeadCaptureForm.find('input,textarea');
		this._$splashLeadCaptureEmail = $('#SplashLeadCaptureEmail');
		this._$splashLeadCaptureName = $('#SplashLeadCaptureName');
		this._$splashLeadCapturePhone = $('#SplashLeadCapturePhone');
		this._$splashLeadCaptureButton = $('#SplashLeadCaptureButton');

		this._$splashLeadCaptureFormFields.on('input', () => {
			this._toggleDisabledSplashLeadCaptureDialogSubmitButton(!this._isSplashLeadCaptureFormValid());
		});

		this._$splashLeadCaptureFormFields.on('keydown keyup', (evt) => {
			if (27 === evt.which) {
				return false;
			}
		});

		// show that the form will autofill a name when one isn't specified, and the name field isn't required
		this._$splashLeadCaptureEmail.find('input').on('keyup', (evt) => {
			if (!this._$splashLeadCaptureName.hasClass('required')) {
				const $nameField = this._$splashLeadCaptureName.find('input');
				const inputVal = $(evt.target).val().split('@', 2)[0] || "";
				$nameField.attr('placeholder', inputVal || $nameField.attr('defaultplaceholder'));
			}
		});

		this._$splashLeadCaptureButton.on('click', () => {
			if (!this._$splashLeadCaptureButton.hasClass('disabled')) {
				this._$splashLeadCaptureDialog.modal('hide');
				const emailAddress = this._$splashLeadCaptureEmail.find('input').val();
				const autoName = this._model.splashScreenFields.required.get('name') ? null : emailAddress.split('@', 2)[0];
				const name = this._$splashLeadCaptureName.find('input').val() || autoName;
				const phone = this._$splashLeadCapturePhone.find('input').val();
				if (emailAddress || name || phone) {
					this._leadModel.setLeadContact({ email: emailAddress, name: name, phone: phone });
					EveryScape.SyncV2.updateName(name, true);
				}
				this.onLeadCaptureDialogClose();
			}
		});
		this._$splashLeadCaptureDialog.on('show.bs.modal', (_evt) => {
			setTimeout(() => {
				this._$splashLeadCaptureEmail.trigger('focus');
			}, 250);
		});
		return this;
	}

	_isFormValid($form, requiredFieldsMap) {
		requiredFieldsMap = requiredFieldsMap ?? new Map();
		let result = true;
		$form.find('input,textarea').each((_i, el) => {
			const name = $(el).attr('name');
			const validator = this._leadModel.validators[name];
			let fieldStateInvalid = false;
			const fieldValue = $(el).val();
			if (requiredFieldsMap.get(name) && !fieldValue) {
				result = false;
				fieldStateInvalid = true;
			}
			if (fieldValue && validator && !validator(fieldValue)) {
				result = false;
				fieldStateInvalid = true;
			}
			$(el).toggleClass('invalid', fieldStateInvalid);
		});
		return result;
	}

	_isChatLeadCaptureFormValid() {
		return this._isFormValid(this._$chatLeadCaptureForm);
	}

	_isRequestAccessFormValid() {
		return this._isFormValid(this._$requestAccessForm);
	}

	_isSplashLeadCaptureFormValid() {
		return this._isFormValid(this._$splashLeadCaptureForm, this._model.splashScreenFields.required);
	}

	_leaseNow(unit, moveInDate) {
		const args = {
			UnitId: unit.SourceUnitId,
			MoveInDate: moveInDate,
			MoveInDateIso: new Date(moveInDate)?.toISOString()?.slice(0, 10),
			SiteId: unit.propertyUnit.property.SourcePropertyId
		};
		const url = unit.LeaseUrlTemplate ? stringInject(unit.LeaseUrlTemplate, args) :
			`https://${unit.propertyUnit.property.RealPageSubdomainId}.onlineleasing.realpage.com/?` + $.param(args);
		window.open(url, '_leaseNow');
		return this;
	}

	_onOpenDatePicker(unit) {
		if (unit?.propertyUnit?.urlOverride) {
			window.open(unit.propertyUnit.urlOverride, '_leaseNow');
			return false;
		}
		const now = new Date();
		let available = new Date(unit.Available);
		if (available < now) {
			available = now;
		}
		const availableMinusOne = new Date();
		availableMinusOne.setDate(available.getDate() - 1);
		const viewer = this;
		$('#ifp-picker').pickadate({
			closeOnSelect: false,
			labelMonthNext: 'Go to the next month',
			labelMonthPrev: 'Go to the previous month',
			labelMonthSelect: 'Pick a month from the dropdown',
			labelYearSelect: 'Pick a year from the dropdown',
			min: [now.getFullYear(), now.getMonth(), 1],
			disable: [{ from: [now.getFullYear(), now.getMonth(), 1], to: [availableMinusOne.getFullYear(), availableMinusOne.getMonth(), availableMinusOne.getDate()] }],
			onRender: function () {
				const currentPicker = this;
				const $leaseNowButton = $('<div>').addClass('ifp-lease-now-button').text('Lease Now');
				const $closeButton = $('<div>').addClass('ifp-close-button').text('X').click(() => { currentPicker.stop(); });
				this.$holder.find('.picker__footer').empty();
				this.$holder.find('.ifp-lease-now-button,.ifp-picker-heading').remove();
				this.$holder.find('.picker__frame').append($leaseNowButton).prepend($closeButton);
				this.$holder.find('.picker__box').prepend($('<div class="ifp-picker-heading">').text('Move In Date'));

				$leaseNowButton.unbind('click').click(function () {
					const value = currentPicker.get('select', 'mm/dd/yyyy');
					if (value) {
						currentPicker.stop();
						viewer._$availabilityDialog.dialog('close');
						viewer._leaseNow(unit, value);
					}
				});
			},
			onClose: function () {
				this.stop();
			}
		});
		$('#ifp-picker').pickadate('picker').open().set('select', `${available.getMonth() + 1}/${available.getDate()}/${available.getFullYear()}`).open();
		return false;
	}

	_onSubmitChatLeadCaptureForm() {
		this._leadModel.setLeadContact(this._getChatLeadCaptureFormContact());
		this._activityMonitor.leadCaptured();
		this._$chatLeadCaptureDialog.dialog('close');
		if (this._chatCallbackAfterLeadCapture) {
			new Promise(this._chatCallbackAfterLeadCapture);
			this._chatCallbackAfterLeadCapture = null;
		}
		return this;
	}

	_onSubmitRequestAccessForm() {
		this._leadModel.setLeadContact(this._getRequestAccessFormContact());
		this._activityMonitor.leadCaptured();
		this._sendRequestAccessNotification();
		this._$requestAccessSection.hide();
		this._leadModel.setHasRequestedProjectAccess(EveryScape.SyncV2.NumericContentId);
		this._$requestAccessDialog.dialog('close');
		return this;
	}

	_sendRequestAccessNotification() {
		const leadContact = this._leadModel.leadContact;
		const sender = {
			email: leadContact.email,
			name: leadContact.name || EveryScape.SyncV2.CurrentState.self.displayName,
			phone: leadContact.phone
		};
		EveryScape.SyncV2.sendDirectNotification('RequestAccess', sender);
		return this;
	}

	_setFormContact($formFields) {
		const leadContact = this._leadModel.leadContact;
		$formFields.each((_i, el) => {
			const name = $(el).attr('name');
			$(el).val(leadContact[name] || '');
		});
		return this;
	}

	/**
	 * Initialize the "Chat Lead Capture" form from stored leadcontact.
	 * @returns {LeadModel}
	 */
	_setChatLeadCaptureFormContact() {
		return this._setFormContact(this._$chatLeadCaptureFormFields);
	}

	/**
	 * Initialize the "Request Access" form from stored leadcontact.
	 * @returns {LeadModel}
	 */
	_setRequestAccessFormContact() {
		return this._setFormContact(this._$requestAccessFormFields);
	}

	_toggleDisabledChatLeadCaptureDialogSubmitButton(disabled) {
		this._$chatLeadCaptureDialogSubmitButton.attr('disabled', disabled);
		return this;
	}

	_toggleDisabledSplashLeadCaptureDialogSubmitButton(disabled) {
		this._$splashLeadCaptureButton.attr('disabled', disabled).toggleClass('disabled', disabled);
		return this;
	}

	_toggleDisabledRequestAccessDialogSubmitButton(disabled) {
		this._$requestAccessDialogSubmitButton.attr('disabled', disabled);
		return this;
	}

	_updateTryItBanner() {
		const bannerEnabled = this._model.isProjectLoaded() && !this._model.isLoggedIn && this._model.unauthenticatedUser &&
			this._model.unauthenticatedUser.projectRoles?.some((pr) => pr.Project?.Id === this._model.projectId);
		$('#TryItBanner').toggle(!!bannerEnabled);

		if (this._settings.UserGuiding?.TryItViewerUserGuidesId && bannerEnabled && !this._tryItUserGuideStarted) {
			this._tryItUserGuideStarted = true;
			userGuiding?.previewGuide(this._settings.UserGuiding.TryItViewerUserGuidesId);
		}

		return this;
	}
}

export { ViewerView }
